/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-admin .altai-file-list .file {
  display: flex;
}
.altai-theme-admin .altai-file-list .file .preview {
  width: 60px;
  height: 60px;
}
.altai-theme-admin .altai-file-dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  background-color: #ECECEC;
  flex-direction: column;
}
.altai-theme-admin .altai-file-dropzone .upload-icon {
  color: #7c7c7c;
  font-size: 50px;
}
.altai-theme-admin .altai-admin-section.file-uploader-embedded {
  padding: 0;
  width: 100%;
}
